import { isValidMail } from './helper-function/isValidMail';

export const initCreateAccountEmail = () => {
    const forms = document.querySelectorAll('[data-create-account-form]');

    [...forms].forEach((form) => {
        const hasAgreeCheckbox = form.dataset.createAccountForm !== 'without-agree-checkbox';

        initForms(form, hasAgreeCheckbox);
    });
};

function initForms(form, hasAgreeCheckbox = true) {
    // VARS
    const userEmail = form.querySelector('[data-create-account-email]');
    const agreeCheckbox = form.querySelector('[data-create-account-checkbox]');
    const fieldsSelector = {
        userEmail: '[data-create-account-email]',
        agreeCheckbox: '[data-create-account-checkbox]',
    };
    const classShowErrorMessage = 'is-visible-error';
    let isValidForm = true;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    agreeCheckbox?.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateUserEmail();
        hasAgreeCheckbox && validateAgreeCheckbox();

        if (!isValidForm) {
            event.preventDefault();
            isValidForm = true;
        }
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnChangeAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);
    }

    // METHODS & FUNCTIONS
    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);

        if (agreeCheckbox.checked) {
            hideErrorMessage(fieldsSelector.agreeCheckbox);
        } else {
            showErrorMessage(fieldsSelector.agreeCheckbox, 'checkbox');
            isValidForm = false;
        }
    }

    function hideErrorMessage(selector) {
        const field = form.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = form.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }
}
